
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import tigermaster from "fdtigermaster-client-sdk";

@Component({
  components: {
    // Navbar,
    // Footer,
  },
})
export default class CustomPayment extends Vue {
  //data
  isLoading = false;
  payment: { amount: string; orderId: string } = { amount: "", orderId: "" };
  orderInfo: {
    clientUserName: string;
    workingCategoryName: string;
    address: string;
  } = {
    clientUserName: "",
    workingCategoryName: "",
    address: "",
    };
  paymentInfo: {
    tradeInfo: string;
    tradeSha: string;
    version: string;
    merchantID: string;
    encryptType: number | null;
  } = {
    tradeInfo: "",
    tradeSha: "",
    version: "",
    merchantID: "",
    encryptType: null,
    };

  $refs!: {
    form: HTMLFormElement;
  };

  //hook
  mounted(): void {
    this.getinfo();
  }

  //computed
  get action(): string {
    return process.env.VUE_APP_NEWEBPAY_URL as string;
  }
  
  //methods
  async getinfo(): Promise<any> {
    let paymentId: string = this.$route.query.paymentId as string;
    let accessToken: string = this.$route.query.accessToken as string;
    tigermaster.auth.loginWithTempToken(accessToken);
    this.payment = (await tigermaster.paymentService.paymentGetHistory(paymentId)) as any;
    this.orderInfo = (await tigermaster.repairOrder.get(this.payment.orderId as string)) as any;
    this.paymentInfo = (await tigermaster.paymentService.paymentCustom(paymentId)) as any;
  }
  Submit(): void {
    this.$refs.form.submit();
  }
}
